import ajax2 from '@/utils/ajax2';

/**
 * 检查号码限制
 */
export function checkPhoneNumberIsCall(data: { PhoneNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/Line/CheckPhoneNumberIsCall',
    method: 'POST',
    data,
  });
}

/**
 * 添加号码呼叫次数
 */
export function addPhoneNumberCallCount(data: { PhoneNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/Line/AddPhoneNumberCallCount',
    method: 'POST',
    data,
  });
}

/**
 * 检查限制
 */
export function checkLimit(data: { ExtensionId: string; DescNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/CallLimit/CheckLimit',
    method: 'POST',
    data,
  });
}

export interface CallRecordByClientParams {
  ExtensionId: string; // 坐席id
  DescNumber: string; // 被叫号码
  StartTime: string; // 拨打开始时间
  AnswerTime: string; // 拨打接通时间 未接通则写 1970-01-01 00:00:00
  IsAnswer: boolean; // 是否接通
}

/**
 * 手动记录通话
 */
export function callRecordByClient(data: CallRecordByClientParams) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/CallLimit/CallRecordByClient',
    method: 'POST',
    data,
  });
}
