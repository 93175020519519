import { createStore } from 'redux';
import getUserInfo from '@/utils/getUserInfo';
// eslint-disable-next-line import/no-cycle
import { CallState } from '@/utils/sip';
import { UserInfo } from 'typings/user';
import { GetCallPlanListItem } from '@/api/call-plan';

export interface CallInfo {
  callState: CallState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}
export interface FuncRole {
  mask: boolean;
  handbutton: boolean;
  dialplan: boolean;
  aicall: boolean;
}
export interface IsHang {
  play: boolean;
  tag: string;
}
export interface Batchdial {
  data: GetCallPlanListItem[] | [];
  isplay: string;
}

export type ReducerAction =
  | {
      type: 'UPDATE_USERINFO';
      payload: UserInfo | null;
    }
  | {
      type: 'UPDATE_CALL_STSATE';
      payload: CallInfo;
    }
  | {
      type: 'UPDATE_ISHOWBIGNUM';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISMOVETOP';
      payload: boolean;
    }
  | {
      type: 'UPDATE_UNREAD_MISSED_CALL_COUNT';
      payload: number;
    }
  | {
      type: 'UPDATE_FUNCROLE';
      payload: FuncRole;
    }
  | {
      type: 'UPDATE_ISREREGISTER';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISSPIN';
      payload: boolean;
    }
  | {
      type: 'UPDATE_SAVETABLELISTDATA';
      payload: any;
    }
  | {
      type: 'UPDATE_BATCHDIAL';
      payload: any;
    }
  | {
      type: 'UPDATE_MODEL';
      payload: string;
    }
  | {
      type: 'UPDATE_ISCAROUSEL';
      payload: boolean;
    }
  | {
      type: 'UPDATE_SIPTAG';
      payload: string;
    }
  | {
      type: 'UPDATE_ISREGISTER';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISSTOPCAROUSEL';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISSIPFREQUENT';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISWHATSTOPCAROUSEL';
      payload: boolean;
    }
  | {
      type: 'UPDATE_WRITECUSTOMDATA';
      payload: { BatchId?: string; Custom?: number };
    }
  | {
      type: 'UPDATE_REMARKS';
      payload: string | null;
    }
  | {
      type: 'UPDATE_ISREGISTERFAILED';
      payload: boolean;
    }
  | {
      type: 'UPDATE_REREGISTRATIONCONT';
      payload: number;
    }
  | {
      type: 'UPDATE_RECONNECTIONCONT';
      payload: number;
    }
  | {
      type: 'UPDATE_BLACKLIST';
      payload: string[];
    }
  | {
      type: 'UPDATE_ISNOWMISSEDCALL';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISCALLBUTTON';
      payload: boolean;
    }
  | {
      type: 'UPDATE_ISAUTOANSWER';
      payload: boolean;
    };

export interface RootState {
  // 用户信息
  userInfo: UserInfo | null;
  // 备注
  remarks: string | null;
  // 是否显示拨号弹窗的拨号盘
  isShowBigNum: boolean;
  // 是否置顶
  isMoveTop: boolean;
  // sip拨号信息
  callInfo: CallInfo;
  // 通话列表 未接电话 未读数量
  unreadMissedCallCount: number;
  // 功能权限(是否启用、编辑)
  funcRole: FuncRole;
  // 是否重新注册
  isReregister: boolean;
  // 加载图标
  isspin: boolean;
  model: string;
  // 是否开始轮播
  isCarousel: boolean;
  // 录音标识
  sipTag: string;
  // 是否注册
  isregister: boolean;
  // 轮播是否停止
  isStopCarousel: boolean;
  // 轮播什么时候停止的
  isWhatStopCarousel: boolean;
  // 是否拨号频繁
  isSipfrequent: boolean;
  // 号码拨打几位接口数据
  writeCustomdata?: { BatchId?: string; Custom?: number };
  // 是否注册失败
  isregisterFailed?: boolean;
  // 重新注册次数
  reRegistrationCont?: number;
  // 重新连接次数
  reconnectionCont: number;
  // 黑名单
  blacklist?: string[];
  // 现在是否有未接电话
  isNowMissedCall: boolean;
  // 是否点击了拨号
  isCallButton: boolean;
  // 是否自动接听
  isAutoAnswer: boolean;
}

export const initState: RootState = {
  userInfo: getUserInfo(),
  remarks: '',
  isShowBigNum: false,
  isMoveTop: false,
  callInfo: { callState: 'idle', data: {} },
  unreadMissedCallCount: 0,
  funcRole: {
    mask: false,
    handbutton: false,
    dialplan: false,
    aicall: false,
  },
  isReregister: true,
  isspin: false,
  model: '',
  isCarousel: false,
  sipTag: '',
  isregister: false,
  isStopCarousel: false,
  isWhatStopCarousel: false,
  isSipfrequent: false,
  isregisterFailed: false,
  reRegistrationCont: 0,
  reconnectionCont: 0,
  blacklist: [],
  isNowMissedCall: false,
  isCallButton: false,
  isAutoAnswer: false,
};

export const store = createStore(
  (state: RootState = initState, action: ReducerAction) => {
    switch (action.type) {
      case 'UPDATE_USERINFO':
        window.electron.send('getUserInfo', action.payload);
        if (action.payload === null) {
          window.sessionStorage.removeItem('userInfo');
        } else {
          window.sessionStorage.setItem(
            'userInfo',
            JSON.stringify(action.payload),
          );
        }
        return { ...state, userInfo: action.payload };
      case 'UPDATE_REMARKS':
        return { ...state, remarks: action.payload };
      case 'UPDATE_ISHOWBIGNUM':
        return { ...state, isShowBigNum: action.payload };
      case 'UPDATE_ISMOVETOP':
        return { ...state, isMoveTop: action.payload };
      case 'UPDATE_CALL_STSATE':
        return { ...state, callInfo: action.payload };
      case 'UPDATE_UNREAD_MISSED_CALL_COUNT':
        return { ...state, unreadMissedCallCount: action.payload };
      case 'UPDATE_FUNCROLE':
        return { ...state, funcRole: action.payload };
      case 'UPDATE_ISREREGISTER':
        return { ...state, isReregister: action.payload };
      case 'UPDATE_ISSPIN':
        return { ...state, isspin: action.payload };
      case 'UPDATE_MODEL':
        return { ...state, model: action.payload };
      case 'UPDATE_ISCAROUSEL':
        return { ...state, isCarousel: action.payload };
      case 'UPDATE_SIPTAG':
        return { ...state, sipTag: action.payload };
      case 'UPDATE_ISREGISTER':
        return { ...state, isregister: action.payload };
      case 'UPDATE_ISREGISTERFAILED':
        return { ...state, isregisterFailed: action.payload };
      case 'UPDATE_ISSTOPCAROUSEL':
        return { ...state, isStopCarousel: action.payload };
      case 'UPDATE_ISSIPFREQUENT':
        return { ...state, isSipfrequent: action.payload };
      case 'UPDATE_ISWHATSTOPCAROUSEL':
        return { ...state, isWhatStopCarousel: action.payload };
      case 'UPDATE_WRITECUSTOMDATA':
        return { ...state, writeCustomdata: action.payload };
      case 'UPDATE_REREGISTRATIONCONT':
        return { ...state, reRegistrationCont: action.payload };
      case 'UPDATE_RECONNECTIONCONT':
        return { ...state, reconnectionCont: action.payload };
      case 'UPDATE_BLACKLIST':
        return { ...state, blacklist: action.payload };
      case 'UPDATE_ISNOWMISSEDCALL':
        return { ...state, isNowMissedCall: action.payload };
      case 'UPDATE_ISCALLBUTTON':
        return { ...state, isCallButton: action.payload };
      case 'UPDATE_ISAUTOANSWER':
        return { ...state, isAutoAnswer: action.payload };
      default:
        return state;
    }
  },
);
