import getStringNumber from '@/utils/getStringNumber';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
import JsSIP, { UA, WebSocketInterface } from 'jssip';
import getUserInfo from '@/utils/getUserInfo';
import { encodeMobile } from '@/utils/encodeMobile';
import logout from './logout';
import { message } from 'antd';
import { getPhoneNumber } from '@/api/lines';
import {
  addPhoneNumberCallCount,
  callRecordByClient,
  CallRecordByClientParams,
  checkLimit,
  checkPhoneNumberIsCall,
} from '@/api/call-barring';
import moment from 'moment';
// import { startwsss } from './palpitate';

export interface Listeners {
  idle?: (ev?: any) => void;
  incoming?: (ev?: any) => void;
  outbound?: (ev?: any) => void;
  hangUp?: (ev?: any) => void;
  failed?: (ev?: any) => void;
  answer?: (ev?: any) => void;
  ended?: (ev?: any) => void;
  confirmed?: (ev?: any) => void;
  connectionFailed?: (ev?: any) => void;
}

export type CallState =
  | 'idle'
  | 'incoming'
  | 'outbound'
  | 'hangUp'
  | 'failed'
  | 'answer'
  | 'ended'
  | 'confirmed'
  | 'connectionFailed';

class SIP {
  /** 允许拨打 客户端呼叫需加10s强制延迟（上一通电话结束后10秒才允许呼出）（实现双方接通状态下挂断增加10s强制延迟) */
  allowCall: boolean = true;

  websocket: WebSocketInterface | undefined = undefined;
  // websocket: any;

  localStream: any;

  reCall = {
    // 拨号时间
    callTime: 0,
    // 重试次数
    callNum: 0,
  };

  // 用户标识
  userAgent: UA | null = null;

  // 呼出session
  outgoingSession: any = null;
  // 呼入session
  incomingSession: any = null;
  //   当前通话的通话信息
  currentSession: any = null;

  // 当前拨号的号码
  currentCallNumber: string = '';

  // 当前是否为群呼来电
  isQunHu: boolean = false;

  // 群呼来电id
  qunHuPhoneId: string = '';

  // 是否自动接听
  isAutoAnswer: boolean = false;

  /**
   * 当前通话的携带数据 在通话结束后返回给前端
   */
  currentCallPayload: any = null;

  /**
   * 当前通话通讯数据
   */
  currentCallData: any = null;

  audioIncoming = document.getElementById(
    'sip-audio-incoming',
  ) as HTMLAudioElement;

  audio = document.getElementById('sip-audio') as HTMLAudioElement;

  listeners: Listeners = {};

  stream: any = {};

  change: (_type: CallState, _data: any, msg?: string) => void = () => {};

  callRecordParams: CallRecordByClientParams | null = null;

  constructor() {
    // JsSIP.debug.enable('JsSIP:*');
    window.onload = () => {
      this.audio = document.getElementById('sip-audio') as HTMLAudioElement;
      this.audioIncoming = document.getElementById(
        'sip-audio-incoming',
      ) as HTMLAudioElement;
    };
  }

  on(action: CallState, callback: () => void) {
    this.listeners[action] = undefined;
    this.listeners[action] = callback;
  }

  /**
   * 触发on绑定的对应动作监听事件
   * @param action 触发监听事件动作
   * @param ev 监听事件携带数据
   */
  dispatch(action: CallState, ev?: any, msg?: string) {
    const userInfo = getUserInfo();
    const selectedLine = userInfo?.selectedLine;
    const number = selectedLine?.line_PhoneNumber || userInfo?.extensionId;
    const line = selectedLine?.lineCode_Name || '无指定线路';
    const uuid = this.getUuid();
    this.getCallId();
    store.dispatch({
      type: 'UPDATE_SIPTAG',
      payload: uuid,
    });
    this.change(action, ev, msg);
    if (this.listeners[action]) {
      this.listeners[action]!(ev);
    }
    const defaultPayloadData = {
      line, // 线路名称
      number, // 线路号码
      uuid, // uuid
      userInfo,
      payload: this.currentCallPayload, // 携带的数据
    };
    switch (action) {
      case 'incoming':
        window.electron.sendMessage({
          action: 'callIncoming',
          payload: {
            code: 0,
            data: defaultPayloadData,
            msg: '电话来电',
          },
        });
        break;
      case 'outbound':
        window.electron.sendMessage({
          action: 'callOutbound',
          payload: {
            code: 0,
            data: defaultPayloadData,
            msg: '电话呼出',
          },
        });
        break;
      case 'ended':
        window.electron.sendMessage({
          action: 'callEnded',
          payload: {
            code: 0,
            data: defaultPayloadData,
            msg: '通话结束',
          },
        });
        break;
      case 'failed':
        window.electron.sendMessage({
          action: 'callFailed',
          payload: {
            code: 0,
            data: defaultPayloadData,
            msg: '通话失败',
          },
        });
        break;
      case 'hangUp':
        window.electron.sendMessage({
          action: 'callHangUp',
          payload: {
            code: 0,
            data: defaultPayloadData,
            msg: '通话挂断',
          },
        });
        break;
      default:
        break;
    }
  }

  /**
   *  注册初始化SIP
   * @returns {object} jsSip
   */
  initSip(username?: string, password?: string) {
    const wssUrl =
      !window.isDevelopment && process.env.REACT_APP_ENV === 'production'
        ? 'wss://huihuvip.zjfantian.cn:7443'
        : 'wss://huihu.zjfantian.cn:7443';
    const sipUrl =
      !window.isDevelopment && process.env.REACT_APP_ENV === 'production'
        ? `sip:${username}@huihuvip.zjfantian.cn`
        : `sip:${username}@huihu.zjfantian.cn`;
    this.websocket = new JsSIP.WebSocketInterface(wssUrl);
    const configuration = {
      sockets: [this.websocket],
      outbound_proxy_set: wssUrl,
      uri: sipUrl,
      password,
      // 是否应该在启动时自动注册.默认true
      register: true,
      // 会话计时器,默认true
      session_timers: false,
      // WebSocket重新连接调度之间的最大间隔(秒)。默认值是30
      connection_recovery_max_interval: 3,
      // WebSocket重新连接尝试之间的最小间隔(秒)。默认值是2
      connection_recovery_min_interval: 1,
      // 注册过期时间
      register_expires: 180,
    };

    return new Promise<void>((resolve, reject) => {
      this.userAgent = new JsSIP.UA(configuration);
      this.userAgent.on('connecting', (data: any) => {
        window.electron.log('为每个传输连接尝试触发');
      });
      this.userAgent.on('connected', (data: any) => {
        window.electron.log('建立传输连接时触发');
      });
      this.userAgent.on('disconnected', (data: any) => {
        this.websocket?.disconnect();
        window.electron.log('ws断开');
        store.dispatch({
          type: 'UPDATE_ISSTOPCAROUSEL',
          payload: true,
        });
        store.dispatch({
          type: 'UPDATE_ISWHATSTOPCAROUSEL',
          payload: true,
        });
        store.dispatch({
          type: 'UPDATE_ISREGISTER',
          payload: false,
        });
        if (store.getState().reconnectionCont <= 2) {
          store.dispatch({
            type: 'UPDATE_RECONNECTIONCONT',
            payload: store.getState().reconnectionCont + 1,
          });
        } else {
          message.error('自动重新sip注册超出次数,请重新登录');
          window.electron.log('自动重新sip注册超出次数,请重新登录');
          logout();
        }
        if (data.error) {
          this.handlError('网络波动', {
            originator: '网络波动',
            cause: `${data.code}`,
          });
          window.electron.log(
            `WebSocket错误断开, code: ${data.code}, 原因: ${data.reason}`,
          );
        } else {
          window.electron.log(
            `当传输连接尝试(或自动重试)失败时激发, code: ${data.code}, 原因: ${data.reason}`,
          );
        }
      });
      /**
       * 被解雇的注册
       */
      this.userAgent.on('unregistered', (data: any) => {
        window.electron.log(
          '检测到连接被注销,触发重新注册事件(注册被注销、重新注册但是已经注册事件会触发此事件)(重新登录忽略此条)',
        );
        // 防止长时间未自动重新注册
        // window.setTimeout(() => {
        // if(!store.getState().isregister){
        //   store.dispatch({
        //     type: 'UPDATE_ISREREGISTER',
        //     payload: true,
        //   });
        // }
        // }, 7000);
      });
      /**
       * 初始化（注册）成功触发。
       */
      this.userAgent.on('registered', () => {
        window.electron.log(`注册成功：${username}`);
        store.dispatch({
          type: 'UPDATE_ISSPIN',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_ISREGISTER',
          payload: true,
        });
        store.dispatch({
          type: 'UPDATE_ISSTOPCAROUSEL',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_ISREGISTERFAILED',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_RECONNECTIONCONT',
          payload: 0,
        });
        if (this.currentSession) {
          store.dispatch({
            type: 'UPDATE_CALL_STSATE',
            payload: {
              callState: 'connectionFailed',
            },
          });
          this.clearCurrentData();
          window.electron.log('检测到拨号残余信息，清除成功');
        }
        resolve();
      });
      /**
       * 初始化（登陆）失败触发
       */
      this.userAgent.on('registrationFailed', (regFailData: any) => {
        this.websocket?.disconnect();
        window.electron.log('ws断开');
        store.dispatch({
          type: 'UPDATE_ISSPIN',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_ISREGISTER',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_ISSTOPCAROUSEL',
          payload: false,
        });
        store.dispatch({
          type: 'UPDATE_ISREGISTERFAILED',
          payload: true,
        });
        store.dispatch({
          type: 'UPDATE_RECONNECTIONCONT',
          payload: 0,
        });
        if (this.currentSession) {
          store.dispatch({
            type: 'UPDATE_CALL_STSATE',
            payload: {
              callState: 'connectionFailed',
            },
          });
          this.clearCurrentData();
          window.electron.log('检测到拨号残余信息，清除成功');
        }
        let data: string = `异常case: ${regFailData.cause}`;
        switch (regFailData.cause) {
          case JsSIP.C.causes.CONNECTION_ERROR:
            data = '登录失败：网络连接失败';
            break;
          case JsSIP.C.causes.REQUEST_TIMEOUT:
            data = '登录失败：请求超时';
            break;
          case JsSIP.C.causes.EXPIRES:
            data = '登录失败：认证过期';
            break;
          case JsSIP.C.causes.AUTHENTICATION_ERROR:
            data = '登录失败：认证失败';
            break;
          case JsSIP.C.causes.SIP_FAILURE_CODE:
            data = '登录失败: SIP 错误码';
            break;
          case JsSIP.C.causes.REJECTED:
            data = '登录失败：账号密码不正确或已被登陆';
            break;
          default:
            data = '登录失败';
            break;
        }
        window.electron.log(data);
        reject(data);
      });
      /**
       * 为传入或传出的会话/呼叫触发。
       */
      this.userAgent.on('newRTCSession', (data: any) => {
        if (
          store
            .getState()
            .blacklist?.indexOf(String(data.request.from._uri._user)) !== -1
        ) {
          data.session.terminate({
            status_code: 604,
          });
          message.warning(
            `您有一条黑名单来电已被拦截,号码:${data.request.from._uri._user}`,
          );
          return;
        }
        if (
          data.originator === 'remote' &&
          getUserInfo()?.tokenCustomState === 1
        ) {
          data.session.terminate({
            status_code: 486,
          });
          message.warning(
            `当前状态为勿扰,拦截通话呼入号码:${data.request.from._uri._user}`,
          );
          return;
        }
        if (
          this.currentSession !== null ||
          this.incomingSession !== null ||
          this.outgoingSession !== null
        ) {
          data.session.terminate({
            status_code: 486,
          });
          message.warning(
            `您有一条来电已被记入未接来电,号码:${data.request.from._uri._user}`,
          );
          store.dispatch({
            type: 'UPDATE_ISNOWMISSEDCALL',
            payload: true,
          });
          return;
        }
        if (
          this.currentSession !== null ||
          this.incomingSession !== null ||
          this.outgoingSession !== null
        ) {
          data.session.terminate({
            status_code: 486,
          });
          message.warning(
            `您有一条来电已被记入未接来电,号码:${data.request.from._uri._user}`,
          );
          store.dispatch({
            type: 'UPDATE_ISNOWMISSEDCALL',
            payload: true,
          });
          return;
        }
        this.currentCallData = data.response;
        // 通话来电 tip:要先监听再做监听音频流
        if (data.originator === 'remote') {
          window.electron.log('电话来电');
          this.incomingSession = data.session;
          this.currentCallNumber = data?.request?.from?._uri._user;
          this.isQunHu = data?.request?.from?._display_name.includes('QunHu')
            ? true
            : false;
          if (this.isQunHu) {
            this.qunHuPhoneId =
              data?.request?.from?._display_name.split(' ')[1];
          }

          this.dispatch('incoming', data);
          this.audioIncoming.currentTime = 0;
          this.audioIncoming.play();
          this.incomingSession.on('peerconnection', (data: any) => {
            data.peerconnection.onaddstream = (ev: any) => {
              this.stream = ev.stream;
              this.audio.srcObject = ev.stream;
              this.audio.onloadstart = () => {
                this.audio.play();
              };
            };
          });
          if (this.isAutoAnswer) {
            window.electron.log('自动接听');
            this.answer();
          }
        } else {
          window.electron.log('电话呼出');
          this.outgoingSession = data.session;
          this.dispatch('outbound', data);
          // 电话呼出 先呼出再做音频流监听
          this.outgoingSession.connection.addEventListener(
            'addstream',
            (ev: any) => {
              this.stream = ev.stream;
              this.audio.srcObject = ev.stream;
              this.audio.onloadstart = () => {
                this.audio.play();
              };
            },
          );
        }
        // 通话结束
        data.session.on('ended', (data: any) => {
          this.audioIncoming.pause();
          this.dispatch('ended', data);
          window.electron.log('sip-通话结束');
          this.clearCurrentData();
        });
        // 通话失败
        data.session.on('failed', (data: any) => {
          if (!this.userAgent?.isRegistered()) {
            window.electron.log('ua未注册');
            return;
          }
          this.audioIncoming.pause();
          if (
            data.originator === 'local' &&
            data.cause === JsSIP.C.causes.REJECTED
          ) {
            this.dispatch('hangUp', data);
            window.electron.log('通话结束（未接）：呼入我方挂断电话');
          } else if (
            data.originator === 'local' &&
            data.cause === JsSIP.C.causes.CANCELED
          ) {
            this.dispatch('hangUp', data);
            window.electron.log('通话结束（未接）：呼出我方挂断电话');
            if (this.callRecordParams) {
              this.callRecordParams = {
                ...this.callRecordParams,
                AnswerTime: '1970-01-01 00:00:00',
                IsAnswer: false,
              };
              this.addCallRecord();
            }
          } else if (
            data.originator === 'remote' &&
            data.cause === JsSIP.C.causes.BUSY
          ) {
            this.dispatch('hangUp', data);
            window.electron.log('通话结束（未接）：呼出对方挂断电话');
            if (this.callRecordParams) {
              this.callRecordParams = {
                ...this.callRecordParams,
                AnswerTime: '1970-01-01 00:00:00',
                IsAnswer: false,
              };
              this.addCallRecord();
            }
          } else if (
            data.originator === 'remote' &&
            data.cause === JsSIP.C.causes.CANCELED
          ) {
            this.dispatch('hangUp', data);
            window.electron.log('通话结束（未接）：呼入我方未操作');
            store.dispatch({
              type: 'UPDATE_ISNOWMISSEDCALL',
              payload: true,
            });
          } else if (
            data.originator === 'remote' &&
            data.cause === JsSIP.C.causes.UNAVAILABLE
          ) {
            this.dispatch('failed', data);
            window.electron.log('通话失败：线路可能存在问题');
          } else if (
            data.originator === 'remote' &&
            data.cause === JsSIP.C.causes.SIP_FAILURE_CODE
          ) {
            this.dispatch('failed', data);
            window.electron.log('通话失败：号码黑名单');
          } else if (
            data.originator === 'local' &&
            data.cause === JsSIP.C.causes.USER_DENIED_MEDIA_ACCESS
          ) {
            this.dispatch('failed', data);
            window.electron.log(
              '通话失败:请检查是否插入麦克风或是否已授权麦克风权限',
            );
          } else {
            this.dispatch('failed', data);
            window.electron.log(
              `通话失败：${data.originator},原因：${data.cause}`,
            );
          }
          this.clearCurrentData();
        });
        // 接受呼叫时触发
        data.session.on('accepted', (data: any) => {
          if (data.originator === 'remote') {
            if (this.currentSession === null) {
              this.currentSession = this.outgoingSession;
            }
            /**
             * 呼出 接听后 要在这里拿到通讯数据(form、to tag)
             */
            this.currentCallData = data.response;
            if (this.callRecordParams) {
              this.callRecordParams = {
                ...this.callRecordParams,
                AnswerTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                IsAnswer: true,
              };
              this.addCallRecord();
            }
            window.electron.log('sip-呼出对方同意接听');
          } else {
            if (this.currentSession === null) {
              this.currentSession = this.incomingSession;
            }
            window.electron.log('sip-呼入我方同意接听');
          }
        });
        // 语音会话建立
        data.session.on('confirmed', (data: any) => {
          this.audioIncoming.pause();
          this.dispatch('confirmed', data);
          if (data.originator === 'remote') {
            if (this.currentSession === null) {
              this.currentSession = this.incomingSession;
            }
            // 电话来电 接听后 要在拿到通讯数据(form、to tag)
            this.currentCallData = data.ack;
          } else {
            if (this.currentSession === null) {
              this.currentSession = this.outgoingSession;
            }
          }
          window.electron.log('sip-语音会话确认建立', data.originator);
        });
        data.session.on('sdp', (data: any) => {
          // window.electron.log('----sdp------');
          // window.electron.log(data);
          // window.electron.log('----sdp------');
          const { sdp } = data;
          data.sdp = sdp.replace(
            '111 63 103 104 9 0 8 106 105 13 110 112 113 126',
            '0 8 111 63 103 104 9 106 105 13 110 112 113 126',
            // '8 111 63 103 104 9 0 106 105 13 110 112 113 126',
          );
        });
        data.session.on('reinvite', (data: any) => {
          window.electron.log('触发重新协商事件');
        });
      });

      this.userAgent.on('newMessage', (data: any) => {
        // console.log('newMessage', data);
      });
      this.userAgent.on('newOptions', (data: any) => {
        // console.log('newOptions', data);
      });
      this.userAgent.on('sipEvent', (data: any) => {
        // console.log('sipEvent', data.request.data.toString());
        // 后台下线功能
        if (
          String(data.request.data).indexOf(
            'Subscription-State: terminated;reason=noresource',
          ) !== -1
        ) {
          window.electron.log('后台强制下线');
          logout();
        }
      });
      this.userAgent.start();
      if (!this.userAgent.isRegistered()) {
        window.electron.log('ua未注册');
        // 未注册的情况下
        // this.userAgent.register();
      }
    });
  }

  /**
   * 发送DTMF
   * @param tone  发送的字符
   */
  sendDTMF(tone = '') {
    const options = {
      transportType: 'RFC2833',
    };
    window.electron.log(
      '发送DTMF',
      tone,
      options,
      this.currentSession.sendDTMF,
    );
    this.currentSession.sendDTMF(tone, options);
  }

  // 注销
  unReg() {
    this.hangup();
    this.clearCurrentData();
    store.dispatch({
      type: 'UPDATE_ISREGISTER',
      payload: false,
    });
    this.userAgent?.unregister();
    this.websocket?.disconnect();
  }

  /**
   * 挂断电话
   */
  //Busy 486：很长的对方在通话中 600：直接显示线路忙
  //Rejected 403：服务器忙 603：直接通话结束
  //Not Found 404：号码不完整 604：该号码无法拨通 空号
  hangup() {
    if (this.incomingSession) {
      this.incomingSession.terminate({
        status_code: 486,
      });
      this.incomingSession = null;
      return;
    }
    if (this.outgoingSession) {
      this.outgoingSession.terminate({
        status_code: 486,
      });
      this.outgoingSession = null;
      return;
    }
    if (this.currentSession && this.currentSession.isEnded() === false) {
      this.currentSession.terminate({
        status_code: 486,
      });
    }
    if (!this.userAgent?.isRegistered()) {
      this.handlError('ua未注册.', `触发点：挂断电话`);
      // this.reRegister();
    }
    this.clearCurrentData();
    // this.userAgent?.terminateSessions();
  }

  /**
   * 通话结束、失败 清理当前通话存储的数据
   */
  clearCurrentData() {
    if (this.audio && !this.audio.paused) {
      this.audio.pause();
    }
    if (this.audioIncoming && !this.audioIncoming.paused) {
      this.audioIncoming.pause();
    }
    this.currentSession = null;
    this.outgoingSession = null;
    this.incomingSession = null;
    this.currentCallData = null;
    this.currentCallPayload = null;
    this.currentCallNumber = '';
    this.isQunHu = false;
    this.qunHuPhoneId = '';
    this.reCall = {
      callNum: 0,
      callTime: 0,
    };
    this.callRecordParams = null;
    store.dispatch({
      type: 'UPDATE_ISCALLBUTTON',
      payload: false,
    });
  }

  // 接听电话
  answer() {
    this.audioIncoming.pause();
    if (!this.userAgent?.isRegistered()) {
      this.handlError('ua未注册.', `触发点：接听电话`);
      // this.reRegister();
      return;
    }
    if (this.incomingSession) {
      window.electron.log('接听电话');
      this.incomingSession.answer({
        mediaConstraints: {
          audio: true,
          video: false,
        },
        mediaStream: this.localStream,
      });
      this.dispatch('answer', this.incomingSession);
    }
  }

  /**
   * 拨打电话
   * @param {string}} number 拨打的号码
   * @param {object}} data 拨号携带的数据
   */
  async callTarget(number: string, data: any = null) {
    if (store.getState().isCallButton) return;
    if (this.reCall.callNum) {
      this.reCall.callNum += 1;
    } else {
      this.reCall = {
        callTime: new Date().getTime(),
        callNum: this.reCall.callNum + 1,
      };
    }
    this.currentCallPayload = data;
    if (!this.userAgent?.isRegistered()) {
      this.handlError('ua未注册.', `触发点：拨打电话`);
      // this.reRegister();
      return;
    }
    if (this.currentSession) {
      this.handlError(`正在通话中。`, number);
      return;
    }
    const target = getStringNumber(number);
    if (!target) {
      this.handlError(`拨打号码不正确：${number}`);
      return;
    }
    store.dispatch({
      type: 'UPDATE_ISCALLBUTTON',
      payload: true,
    });
    let extraHeaders: string[] = [];
    let seatNumber = getUserInfo()?.selectedLine?.line_PhoneNumber;
    let lineCode = getUserInfo()?.selectedLine?.lineCode_Code;
    if (lineCode === 'phoneDataBase') {
      try {
        const res = await getPhoneNumber({ PhoneNumber: number });
        if (res.code === '000000') {
          if (!res.data) {
            message.warning('号码库中没有号码');
            store.dispatch({
              type: 'UPDATE_ISCALLBUTTON',
              payload: false,
            });
            return;
          }
          if (!res.data.phoneNumber || !res.data.lineCode) {
            message.warning('线路号码错误');
            store.dispatch({
              type: 'UPDATE_ISCALLBUTTON',
              payload: false,
            });
            return;
          }
          seatNumber = res.data.phoneNumber;
          lineCode = res.data.lineCode;
          window.electron.log(
            `使用号码库拨号,${res.data.lineCode}:${res.data.phoneNumber}`,
          );
        } else {
          message.error(`获取号码库线路失败: ${res.message}`);
          store.dispatch({
            type: 'UPDATE_ISCALLBUTTON',
            payload: false,
          });
          return;
        }
      } catch (e) {
        const err = e as Error;
        message.error(`获取号码库线路失败: ${err.message}`);
        store.dispatch({
          type: 'UPDATE_ISCALLBUTTON',
          payload: false,
        });
        return;
      }
    }
    if (lineCode === 'A005') {
      if (!/^1[23456789]\d{9}$/.test(target)) {
        message.warning('A005线路不能拨打固话');
        store.dispatch({
          type: 'UPDATE_ISCALLBUTTON',
          payload: false,
        });
        return;
      }
    }
    if (seatNumber) {
      extraHeaders = [`X-Outbound-Caller-Id-Number: ${seatNumber}`];
      // 检查号码限制及记录一次呼叫次数
      try {
        const extensionId = getUserInfo()?.extensionId;
        if (extensionId) {
          this.callRecordParams = {
            ExtensionId: extensionId,
            DescNumber: target,
            AnswerTime: '1970-01-01 00:00:00',
            StartTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            IsAnswer: false,
          };
          const res2 = await checkLimit({
            DescNumber: target,
            ExtensionId: extensionId,
          });
          if (res2.code === '000000') {
            if (!res2.data) {
              message.warning('号码使用超限');
              store.dispatch({
                type: 'UPDATE_ISCALLBUTTON',
                payload: false,
              });
              return;
            }
          } else {
            // message.error(`检查限制失败: ${res2.message}`);
            // store.dispatch({
            //   type: 'UPDATE_ISCALLBUTTON',
            //   payload: false,
            // });
            // return;
          }
        }
        const res = await checkPhoneNumberIsCall({ PhoneNumber: seatNumber });
        if (res.code === '000000') {
          if (!res.data) {
            message.warning('号码使用超限');
            store.dispatch({
              type: 'UPDATE_ISCALLBUTTON',
              payload: false,
            });
            return;
          }
        } else {
          message.error(`检查号码限制失败: ${res.message}`);
          store.dispatch({
            type: 'UPDATE_ISCALLBUTTON',
            payload: false,
          });
          return;
        }

        const res1 = await addPhoneNumberCallCount({ PhoneNumber: seatNumber });
        if (res1.code === '000000') {
          if (!res1.data) {
            message.warning('添加号码呼叫次数失败');
            store.dispatch({
              type: 'UPDATE_ISCALLBUTTON',
              payload: false,
            });
            return;
          }
        } else {
          message.error(`添加号码呼叫次数失败: ${res.message}`);
          store.dispatch({
            type: 'UPDATE_ISCALLBUTTON',
            payload: false,
          });
          return;
        }
      } catch (error) {
        const err = error as Error;
        message.error(`号码限制错误: ${err.message}`);
        store.dispatch({
          type: 'UPDATE_ISCALLBUTTON',
          payload: false,
        });
        return;
      }
    }
    const options = {
      extraHeaders,
      mediaConstraints: {
        audio: true,
        video: false,
        mandatory: { maxWidth: 640, maxHeight: 360 },
      },
      mediaStream: this.localStream,
      // sessionTimersExpires: 420,
    };
    window.electron.log('拨打号码：', encodeMobile(target));
    this.currentCallNumber = target;
    /**
     * 拨号增加线路前缀
     *  号码库的号码自动增加*
     */
    const _linecode =
      getUserInfo()?.selectedLine?.lineCode_Code === 'phoneDataBase';
    const callTargetPrefix = lineCode
      ? `${lineCode}${_linecode ? '' : '*'}`
      : '';
    this.outgoingSession = this.userAgent.call(
      callTargetPrefix + target,
      options,
    );
  }

  // 麦克风禁音
  isMute(data: boolean) {
    if (data) {
      this.outgoingSession && this.outgoingSession.mute();
      this.incomingSession && this.incomingSession.mute();
      this.currentSession && this.currentSession.mute();
    } else {
      this.outgoingSession && this.outgoingSession.unmute();
      this.incomingSession && this.incomingSession.unmute();
      this.currentSession && this.currentSession.unmute();
    }
  }

  // 通话转接
  callRefer(extension: string) {
    const lineCode = getUserInfo()?.selectedLine?.lineCode_Code;
    const callTargetPrefix = lineCode ? `${lineCode}*${extension}` : extension;
    if (this.currentSession) {
      if (/^1[34578]\d{9}$/.test(extension)) {
        this.currentSession.refer(
          `sip:${callTargetPrefix}@huihuvip.zjfantian.cn;user=phone`,
        );
      } else {
        this.currentSession.refer(extension);
      }
    } else {
      message.warning('当前通话还未建立, 请先建立通话后再进行转接!');
    }
  }

  // 重新注册
  // reRegister() {
  //   if (this.currentSession) {
  //     this.hangup();
  //   }
  //   this.userAgent?.unregister();
  //   this.websocket?.disconnect();
  //   this.clearCurrentData();
  //   store.dispatch({
  //     type: 'UPDATE_ISREREGISTER',
  //     payload: true,
  //   });
  // }

  /**
   * 报错
   */
  handlError(msg: string, data: any = '') {
    this.dispatch('failed', data, msg);
    window.electron.log('sip-failed', msg);
  }

  // 获取 uuid（form_tag:to_tag ）
  getUuid() {
    if (this.currentCallData && this.currentCallData.from_tag) {
      let { from_tag, to_tag } = this.currentCallData;
      from_tag = from_tag || '';
      to_tag = to_tag || '';
      return `${from_tag}:${to_tag}`;
    }
    if (this.currentSession && this.currentSession._from_tag) {
      let { _from_tag, _to_tag } = this.currentSession;
      _from_tag = _from_tag || '';
      _to_tag = _to_tag || '';
      return `${_from_tag}:${_to_tag}`;
    }
    return '';
  }

  // 获取call_id
  getCallId() {
    let Id: string = '';
    if (this.outgoingSession) {
      Id = this.outgoingSession.id.replace(this.outgoingSession._from_tag, '');
    }
    if (this.incomingSession) {
      Id = this.incomingSession.id.replace(this.incomingSession._from_tag, '');
    }
    if (this.currentSession) {
      Id = this.currentSession.id.replace(this.currentSession._from_tag, '');
    }
    return Id;
  }
  // 手动呼出记录通话记录
  addCallRecord() {
    if (this.callRecordParams !== null) {
      callRecordByClient(this.callRecordParams)
        .then((result) => {
          if (result.code === '000000') {
            if (!result.data) {
              message.warning(`手动记录通话失败: ${result.message}`);
              window.electron.log('手动记录通话失败' + JSON.stringify(result));
            }
          }
        })
        .catch((err) => {
          message.error(`手动记录通话失败: ${err.message}`);
          window.electron.log('手动记录通话失败' + JSON.stringify(err));
        });
    }
  }
  // 设置是否自动接听
  setAutoAnswer(data: boolean) {
    this.isAutoAnswer = data;
    store.dispatch({
      type: 'UPDATE_ISAUTOANSWER',
      payload: data,
    });
  }
}
const Sip = new SIP();
export default Sip;
